// src/firebase.js
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";

const stripeApiKey = import.meta.env.VITE_APP_STRIPE_SECRET_KEY; // Replace with your actual Stripe secret key

const firebaseConfig = {
   apiKey: "AIzaSyBH37h4mB14cIxjnh3v_mVYAJFYs7A6RcI",
  authDomain: "japanieasy.firebaseapp.com",
  projectId: "japanieasy",
  storageBucket: "japanieasy.appspot.com",
  messagingSenderId: "344175791814",
  appId: "1:344175791814:web:67d44646ff8d9b2c8751c7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const addUserToSheet = async (email, password, name, uid, customerId) => {
  const url = "https://api.sheety.co/1f9a2b95f3899a00e7849c10b6ab36be/reelmaker/users";
  const body = {
    user: {
      email, password, name, uid, customerId
    },
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await response.json();
    // console.log("New user added:", json.user);
  } catch (error) {
    console.error("Error adding user:", error);
  }
};

const createCustomer = async (email) => {
    // console.log('createCustomer: ', email);

    try {
      const response = await fetch('https://api.stripe.com/v1/customers', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${stripeApiKey}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email,          
        }),
      });

      const data = await response.json();
      return data;
    } catch (err) {
      console.error();(err.message);
    }
  };

const signUpWithEmail = async (email, password, name) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  // console.log(userCredential);
  await updateProfile(userCredential.user, { displayName: name });
  const customer = await createCustomer(email);
  addUserToSheet(email, password, name, userCredential?.user?.uid, customer?.id);
  return {user: userCredential, email, name, uid: userCredential?.user?.uid, customerId: customer?.id};
};

const signInWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signOutUser = () => {
  return signOut(auth);
};

const sendPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export { auth, signUpWithEmail, signInWithEmail, signOutUser, sendPasswordReset };