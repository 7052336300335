// src/components/LoadingScreen.jsx
import { Center, Container, Loader, Skeleton } from '@mantine/core';

const LoadingScreen = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '10vh' }}>
      <Center>
        <Loader color="#474f85" size="xl" type="bars" />
      </Center>
      <>
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </>
    </Container>
  );
};

export default LoadingScreen;
